import React, { useEffect, useState, useRef } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import API_URL from './ApiData'
import { Swiper, SwiperSlide } from 'swiper/react';
import copy from "copy-to-clipboard"
import FileSaver from "file-saver";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const AllNews = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const swiperRef = useRef(null);
  
    const openLightbox = (index) => {
      setLightboxOpen(true);
      setLightboxIndex(index);
    };
  
    const closeLightbox = () => {
      setLightboxOpen(false);
      setLightboxIndex(0);
    };
  
    const handleMouseEnter = () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.autoplay.stop();
      }
    };
  
    const handleMouseLeave = () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.autoplay.start();
      }
    };


    
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageList, setPageList] = useState([1,2,3]);
    const [lastPage, setLastPage] = useState(10);
    const [nextBtnStatus, setNextBtnStatus]=useState(false)
    const [prevBtnStatus, setPrevBtnStatus]=useState(false)
    const inputRef = useRef(null)
    const [popData, setPopData] =useState()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setShow(true)
        setPopData(data)
        console.log('vikasP', popData)
    }
    const regex = /(<([^>]+)>)/gi;
    const [isAccept, setIsAccept] = useState(false)
    const [isload, setIsload] = useState(false)
    const [imgArry, setImgAry] = useState([])
    const getCurrentTimeInIST = () => {
        const now = new Date();
        // Calculate IST by adding 5 hours and 30 minutes (in milliseconds)
        const istTime = new Date(now.getTime() + (5.5 * 60 * 60 * 1000));
        // Format the date to 'YYYY-MM-DD HH:mm:ss'
        const formattedDate = istTime.toISOString().replace('T', ' ').substring(0, 19);
        return `${formattedDate} +05:30`; // Append the IST offset
    };
    // Updated CreateStory function
const [msg, setMsg]= useState('')
const CreateStory = (createData) => {
  var formData = new FormData();
  if (!createData.title) {
      setMsg('Title can not be blank.');
  } else if (!createData.summary) {
      setMsg('Summary can not be blank.');
  } else {
      formData.append('assign_me', createData.assign_me || '');
      formData.append('title', createData.title);
      formData.append('summary', createData.summary);
      const getStateApi = API_URL.Create_Story_URL;
      axios({
          method: "post",
          url: getStateApi,
          data: formData,
          headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
          },
      }).then((res) => {
          setTimeout(50000);
      });
  }
}; 
    const handleSubmit =  (type) => {
        // e.preventDefault();
        setIsload(true)
         if(!popData.title){
           alert('Title can not be blank.');
           setIsload(false)
           return false;
         }else if(!popData.description){
           alert('description can not be blank.');
           setIsload(false)
           return false;
         }
    // const NewAPArry = imgArry.map(video => video.id);

         const temp = imgArry.map(image => image.id);

         let tempdata = {
           id: popData.id,
           title: popData.title,
           description: popData.description,
           image_ids: temp,
           event_type:popData.event_type,
           stringers: popData.stringers && popData.stringers.length > 0 ? [parseInt(popData.stringers)]: [],
           status: type== 'hold' ? 'on_hold' : type== 'pending' ? 'pending': type== 'submitted' ? 'submitted' :'',
           language: popData.language,
           mark_important:popData.mark_important,
           remarks: popData.remarks,
           updated_at: getCurrentTimeInIST(),
         }
         let tempAccptdata = {
     
           id: popData.id,
           title: popData.title,
           description: popData.description,
           image_ids: temp,
           stringers: popData.stringers && popData.stringers.length > 0 ? [parseInt(popData.stringers)]: [],
           status: 'approved',
           language: popData.language,
           accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
           remarks: popData.remarks,
           mark_important:popData.mark_important,
           updated_at: getCurrentTimeInIST(),
         }
         let tempRejectdata = {

           id: popData.id,
           title: popData.title,
           description: popData.description,
           image_ids: temp,
           stringers: popData.stringers && popData.stringers.length > 0 ? [parseInt(popData.stringers)]: [],
           status: 'rejected',
           mark_important:popData.mark_important,
           language: popData.language,
           accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
           remarks: popData.remarks,
           updated_at: getCurrentTimeInIST(),
     
         }
         if(type && type =='approved'){
          // Integrating CreateStory
          CreateStory({
           title: popData.title,
           summary: popData.description,
           assign_me: true
       });
      }
         axios({
           method: "put",
           url: `${API_URL.CREATE_NEWS}${popData.id}/`,
           data: type && type == 'approved' ? tempAccptdata : type == 2 ? tempRejectdata : tempdata,
           headers: {
             'Content-Type': 'application/json',
             Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
           },
         }).then((response) => {
           if (response.status === 200) {
             console.log('Success:', response.data);
             setIsload(false)
             setShow(false)
             fetchData(currentPage, localStorage.langg);
           }
         })
       }
    const handleNextPage = () => {
        if(nextBtnStatus){
          setCurrentPage(currentPage + 1);
          let newArry = pageList;
          let newAddArray = pageList[pageList.length-1]+1;
          // if(currentPage > 2){
            let newRemoveArray = pageList[0];
          //
          // }
          newArry.shift(newRemoveArray);
          newArry.push(newAddArray);
            setPageList(newArry);
            }
      }
          
         
      const handlePrevPage = () => {
      if(currentPage > 1){
        setCurrentPage(currentPage - 1);
        // setCurrentPage(currentPage + 1);
        let newArry = pageList;
        let newAddArray = pageList[pageList.length-1];
        let newRemoveArray = pageList[0]-1;
          newArry.unshift(newRemoveArray);
        newArry.pop(newAddArray);
      setPageList(newArry);
      }
      }
    
    
      const handleStartPage = () => {
      // if(requestFlag){
        setCurrentPage(1);
        let lastArrtty = [1, 2, 3]
        setPageList(lastArrtty);
      //  }
      }
    const handleChange = event=>{
        const {name, value} =event.target;
        setInputData({ ...inputData, [name]: value })
        setCurrentPage(1)
    
      }
      const [inputData, setInputData] = useState({
        title: ''
      })
    const fetchData = (page, ln)=>{
        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
        };

        // Fetch data from the API when the component mounts
        let apiUrl = `https://stingers.ianswire.in/api/news/?page=${currentPage}&language=hi&query=`
        if(inputData.title){
            apiUrl = `${apiUrl}${inputData.title}`
          }
        axios.get(apiUrl, { headers })
            .then(response => {
                setNewsData(response.data.results);
                if(response.data.next){
                    setNextBtnStatus(true)
                }else{
                    setNextBtnStatus(false)
                }
                if(response.data.previous){
                    setPrevBtnStatus(true)
                }else{
                    setPrevBtnStatus(false)
                }
                setLastPage(response.data.count)
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }
    const copyToText = async () => {
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = popData.description
     
        // Remove most HTML tags except for <p>
        let copyt = ''
     
        // Replace specific <p> tags with double line breaks
        //copy = copy.replace(/<p><\/p>/g, '\n\n');
     
        // Keep specific <p> tags at the bottom
        const specificTags = tempDiv.querySelectorAll('p')
        specificTags.forEach((tag) => {
           copyt += '\n\n' + tag.innerHTML;
        });
     
        // Combine title, short_desc, and modified content
        let final_copy = '';
        //if(final_copy){
           final_copy = `${popData.title}\n\n ${copyt}`
           final_copy=final_copy.replace("<br>","\n")
           final_copy=final_copy.replace('<br>','\n')
           final_copy = final_copy.replace(/&nbsp;/g, ' ')
           final_copy=final_copy.replace('<br data-mce-bogus="1">','')
           copy(final_copy);
           //handleSubmit(popData.title, popData.id)
        //}
        // Copy the final content to the clipboard
        //await navigator.clipboard.writeText(final_copy);
        }
        const downloadToText =(url)=>{
            // if(eventL && eventL== 1){
            //    window.removeEventListener();
            //    setEventL(eventL+1)
            // }
         //    console.log('vikkk',caches)
         //    caches.keys().then((names) => {
         //       names.forEach((name) => {
         //           caches.delete(name);
         //       });
         //   });
            setTimeout(image2Download(url), 50000)
            
         
         }
        const image2Download = async (gdata) => {
            //alert(gdata);
        //    const tempDiv = document.createElement('div');
        //    tempDiv.innerHTML = popData.description;
     
        //    // Remove most HTML tags except for <p>
        //    let copyt = '';
     
        //    // Replace specific <p> tags with double line breaks
        //    //copy = copy.replace(/<p><\/p>/g, '\n\n');
     
        //    // Keep specific <p> tags at the bottom
        //    const specificTags = tempDiv.querySelectorAll('p');
        //    specificTags.forEach((tag) => {
        //       copyt += '\n\n' + tag.innerHTML;
        //    });
     
        //    // Combine title, short_desc, and modified content
        //    let final_copy = `${popData.title}\n\n ${copyt}`;
        //    final_copy=final_copy.replace('<br>','\n')
        //    final_copy = final_copy.replace(/&nbsp;/g, ' ')
        //    final_copy=final_copy.replace('<br data-mce-bogus="1">','')
        //    // Copy the final content to the clipboard
        //    copy(final_copy);
        //    const filename = popData.id+".txt"; // Replace with the desired file name
     
        //    const blob = new Blob([gdata], { type: 'text/plain' });
        //    const url = URL.createObjectURL(blob);
     
        //    const a = document.createElement('a');
        //    a.href = url;
        //    a.download = filename;
     
        //    document.body.appendChild(a);
        //    a.click();
     
        //    window.URL.revokeObjectURL(url);
        //    document.body.removeChild(a);
          // handleSubmit(collectionData.title, collectionData.id)
          let timestamp = new Date().getTime();
          fetch(`${gdata}?t=${timestamp}`)
          //fetch(gdata)
          .then(response => response.blob())
          .then(blob => {
            console.log('ÍANSSS',blob);
            if(blob && blob.size && blob.size > 1000){
             // setIsLoading(false);
            }
              const fileSaver = FileSaver.saveAs(blob, gdata);
              fileSaver.onwriteend = function () {
              console.log('Download completed.');
              // alert();
             //setIsLoading(false);
              };
              fileSaver.onerror = function () {
              console.error('Download failed.');
              };
          })
          .catch(e => console.error(e));
        }
    useEffect(() => {
        // Set up headers
        
        fetchData(currentPage, localStorage.langg);
        setShow(false)
        if(localStorage && localStorage.length > 0 && localStorage.publishOpt && localStorage.publishOpt == 'publish'){
            setIsAccept(true)
          }
    }, [currentPage]);

    return (
        <>
    <div className="wrapper">
        <Header />
            <div className="content-wrapper">
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                            {  !loading && 
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                        <div className="row stn-search-hed">
                                            <div className="col-lg-8 d-flex align-items-center ev-stn">
                                                <h5 className="card-title"><span>All News</span> <img className="nav-i-icon" src="/assets/icons/stn-news-b.png"/></h5>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="search-form-allsnt">
                                                    <form onSubmit={(event) => {event.preventDefault(); // Prevent the default form submission
                                                    fetchData(1, ''); // Trigger the search functionality
                                                    }}>
                                                        <input type="search" ref={inputRef} placeholder="Search...." name="title" value={inputData.title} onChange={handleChange}/>
                                                        <span className="input-group-text border-0" onClick={()=>{
                                                fetchData(1,'')
                                                }}><i className="fas fa-search" aria-hidden="true"></i></span>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="no-more-tables">
                                        <table className="col-md-12 table table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th className="col-md-5">Heading</th>
                                                        <th className="numeric">Created by</th>
                                                        <th className="numeric">Approved by</th>
                                                        <th className="numeric">Accepted by</th>
                                                        <th className="numeric">Created & Updated at</th>
                                                        <th className="numeric">Status</th>
                                                        <th className="numeric">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {newsData.map((newsItem) => (
                                                        <tr onClick={()=>handleShow(newsItem)} key={newsItem.id}>
                                                            <td data-title="ID">{newsItem.id}</td>
                                                            <td data-title="Heading">{newsItem.title}</td>
                                                            <td data-title="Created by" className="numeric">{newsItem.created_by}</td>
                                                            
                                                            <td data-title="Approved by" className="numeric">{newsItem.accepted_by}</td>
                                                            <td data-title="Accepted by" className="numeric">{newsItem.accepted_by}</td>
                                                            <td data-title="Created & Updated at" className="numeric">
                                                                <div>
                                                                    <strong>Created at:</strong> {new Date(newsItem.created_at).toLocaleString()}
                                                                </div>
                                                                <div>
                                                                    <strong>Updated at:</strong> {new Date(newsItem.updated_at).toLocaleString()}
                                                                </div>
                                                            </td>
                                                            <td data-title="Status" className="numeric">
                                                                {newsItem.status ? (
                                                                    <span className={`badge ${getStatusClass(newsItem.status)}`}>
                                                                        {newsItem.status =='on_hold' ? 'hold': newsItem.status}
                                                                        {newsItem.mark_important ? (
                                                                            <div className="spinner-grow text-danger" role="status">
                                                                                <span className="visually-hidden">Loading...</span>
                                                                            </div>
                                                                        ) : ''}
                                                                    </span>
                                                                ) : ''}
                                                            </td>
                                                            <td data-title="Action" className="numeric">
                                                                <NavLink to={'/edit-stn-news/' + newsItem.id} className="btn btn-sm btn-primary mx-1">
                                                                    <i className="fas fa-edit viewedit" aria-hidden="true"></i>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {/* Pagination (You can customize pagination functionality here) */}
                                            <div className="pagination">
                                                <ul>
                                                    {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}
                                                    {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                                                    {pageList.map((data, i)=>{
                                                    return(
                                                    <li key={i} onClick={()=>{
                                                    setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                                                        )
                                                    })}
                                                    <li onClick={handleNextPage}><a href="#" className="prevnext">Next</a></li>
                                                </ul>                                            							
                                            </div> 
                                        </div>
                                    </div>
                          

                                </div>
                            }
                                {loading && 
                                <div className="card-body">
                                    <div className="load-img">
                                        Loading...
                                        <div className="loader"></div>
                                    </div>  
                                </div>  
                            }
                            </div>
                        </div>
                        <>
                        <div className='news-r-pop'>
                        <Modal id='news-r-pop'
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='news-r-cont'>
                                <h4>{popData && popData.title ? popData.title: ''}</h4>
                                <div className="share-social-icons">
                                 <ul>
                                    {/* <li className="downloadhbtn">
                                       <a alt="Download file" href="#" title="Download file" onClick={downloadToText}>
                                          <span>Download image</span>
                                          <i alt="Download file" className="fas fa-download"> </i>
                                       </a>
                                    </li> */}
                                    <li className="downloadhbtn">
                                       <a href="#" title="Copy Text" onClick={copyToText}>
                                       <span>Copy TXT</span>
                                          <i className="fas fa-copy" id="copyicon"></i>
                                       </a>
                                    </li>
                                 </ul>
                              </div>
                                   {popData && popData.images && popData.images.length == 1 && 
                                    <img src={popData.images[0].image  ? popData.images[0].image : ''} />
                                    }
                                     {popData && popData.images && popData.images.length > 1 &&
                                            <section id="testimonials" className="testimonials" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <div className="main-heading">
                                              {/* <h2 className="heading">{popData && popData.title ? popData.title : ''}</h2> */}
                                            </div>
                                            <Swiper
                                              ref={swiperRef}
                                              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                              spaceBetween={30}
                                              slidesPerView={1}
                                              autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                              }}
                                              navigation
                                              breakpoints={{
                                                320: {
                                                  slidesPerView: 1,
                                                  spaceBetween: 10,
                                                },
                                                480: {
                                                  slidesPerView: 1,
                                                  spaceBetween: 20,
                                                },
                                                668: {
                                                  slidesPerView: 1,
                                                  spaceBetween: 30,
                                                },
                                                900: {
                                                  slidesPerView: 1,
                                                  spaceBetween: 30,
                                                },
                                              }}
                                            >
                                              <div className="clients-slider swiper four-col-category-list" data-aos="fade-up" data-aos-delay="100">
                                                <div className="swiper-wrapper four-col-category-list">
                                                  {popData.images.map((deep, d) => {
                                                    return (
                                                      <SwiperSlide key={d}>
                                                        <div className="testimonial-item four-col-category-list">
                                                          <figure>
                                                            <div className="img-area-four-col-category-list">
                                                              <NavLink to={'#'}>
                                                                <img src={deep.image} title={'deep.title'} alt="#" />
                                                                <p class="gcounter"> {d+1} <span> / {popData.images.length}</span></p>
                                                              </NavLink>
                                                              {/* <ul>
                                                                    <li className="downloadh-img-btn">
                                                                    <a alt="Download file" href="#" title="Download file" onClick={()=>downloadToText(deep.image)}>
                                                                        <span>Download image</span>
                                                                        <i alt="Download file" className="fas fa-download"> </i>
                                                                    </a>
                                                                    </li>
                                                                </ul> */}
                                                               <a className="stn-download-btn" alt="Download file" href="#" title="Download file" onClick={()=>downloadToText(deep.image)}>
                                                                    <span>Download image</span>
                                                                    <i alt="Download file" className="fas fa-download"> </i>
                                                                </a> 
                                                            </div>
                                                          </figure>
                                                        </div>
                                                      </SwiperSlide>
                                                    )
                                                  })}
                                                </div>
                                                <div className="swiper-pagination"></div>
                                              </div>
                                            </Swiper>
                                          </section>
                                     }   
                                    
                                   
                                    {   popData && popData.description  && 
                                        <div className='r-dic' dangerouslySetInnerHTML={{ __html:  popData.description }}/>

                                    }
                                    <div className='r-popdate'>   
                                        {popData && popData.created_at && 
                                            <span>
                                                <strong>Created at:</strong> {new Date(popData.created_at).toLocaleString()}
                                            </span>
                                        }
                                        { popData && popData.updated_at && 
                                            <span>
                                                <strong>Updated at:</strong> {new Date(popData.updated_at).toLocaleString()}
                                            </span>
                                        } 
                                        <div className="col-md-12 text-left mt-4 pub-btn-fixedd">
                                        
                                        {  localStorage && localStorage.length > 0 && isAccept &&     

                                        <button className="btn btn-success" type="button" onClick={() => handleSubmit('approved')}>Approve</button>
                                        }
                                        {  localStorage && localStorage.length > 0 && isAccept && 
                                        
                                        <button className="btn btn-warning" type="button" onClick={() => handleSubmit('hold')}>Hold</button>

                                        }                    
                                        {  localStorage && localStorage.length > 0 && isAccept &&     
                                        <button className="btn btn-danger" type="button" onClick={() => handleSubmit(2)}>Reject</button>
                                        } 
                                    </div>  
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        </div>
                        </>                        
                    </div>
                </section>
            </div>

                            
            <Footer />
        </div>
        </>
    );
};

// Helper function to determine badge color based on status
const getStatusClass = (status) => {
    switch (status) {
        case 'approved':
            return 'bg-success';
        case 'on_hold':
            return 'bg-warning';
        case 'rejected':
            return 'bg-danger';
        default:
            return 'pending_snt';
    }
};
const handleView = (id) => {
    // Replace this with actual view functionality
    console.log(`Viewing news item with ID: ${id}`);
};

// Function to handle edit button click
const handleEdit = (id) => {
    // Replace this with actual edit functionality
    console.log(`Editing news item with ID: ${id}`);
};

export default AllNews;
